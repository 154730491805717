import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"
import Button from "../components/Button"

const DonatePage = ({ data }) => (
  <Layout>
    <SiteMetadata title="About" description="Sample description" />

    <div className="bg-gray-100">
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
            <h1 className="text-3xl mb-3 leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Test Donate Page for Gio
            </h1>

            <h2 className="text-xl mb-3 leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
            Welcome to Reno Gio! Rene says Hi.
            </h2>
            <div className="mt-4 mb-4 leading-loose">
            Reno (/ˈriːnoʊ/ REE-noh) is a city in the northwest section of the U.S. state of Nevada, about 22 miles (35 km) from Lake Tahoe, known as "The Biggest Little City in the World".[3] Known for its casino and tourism industry, Reno is the county seat and largest city of Washoe County and sits in a high desert river valley at the foot of the Sierra Nevada. Its downtown area (along with the neighboring city Sparks) occupies a valley informally known as the Truckee Meadows, which because of large-scale investments from Seattle and Bay Area companies such as Amazon, Tesla, Panasonic, Microsoft, Apple, and Google has become a new major technology hub in the United States.[4] The city is named after Union Major General Jesse L. Reno, who was killed in action during the American Civil War at the Battle of South Mountain on Fox's Gap.
           
              
              </div> 
              <a
              href="https://aifl.z2systems.com/np/clients/aifl/donation.jsp?campaign=124&"
              
              >
              <button
              className={
                  "bg-yellow-500 text-black  active:bg-gray-100" +
                  " text-xs font-bold uppercase px-4 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
              }
              type='button'
              style={{ transition: "all .15s ease" }}>
              Donate Here
          </button></a>
              
          </div>
          <div className="w-full md:w-1/2 xl:w-2/5 md:pl-12">
            <Img
              fluid={data.reno.childImageSharp.fluid}
              alt="John Doe"
              className="rounded-md shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
    <Newsletter />
  </Layout>
)

export default DonatePage

export const query = graphql`
  query {
    reno: file(relativePath: { eq: "reno.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
